import React, { useState, useEffect } from 'react';
import BottomNavBar from '../navbar';
import scanImage from '../../img/scan.svg';
import locationImage from '../../img/location.svg';
import QrScanner from 'react-qr-scanner';
import Modal from '../modal';
import Cookies from "js-cookie";

const MapComponent = () => {
    const [location, setLocation] = useState({ lat: 35.1595, lng: 129.0604 });
    const [locationInfo, setLocationInfo] = useState('Loading location...');
    const [scanning, setScanning] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [mediaStream, setMediaStream] = useState(null);
    const [map, setMap] = useState(null);
    const [bikeLocations, setBikeLocations] = useState([]);
    const [cameraError, setCameraError] = useState(null);

    // Device keys array instead of multiple fetch functions
    const deviceKeys = [
        '074241400196', '074241400153', '074241400142', '074241400169',
        '074241400118', '074241400186', '074241400189', '074241400184',
        '074241400114', '074241400164', '074241400124', '074241400141',
        '074241400132', '074241400103', '074241400123', '074241400161',
        '074241400186'
    ];

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    setLocation(pos);
                    setLocationInfo(`Lat: ${pos.lat.toFixed(4)}, Lng: ${pos.lng.toFixed(4)}`);
                    initializeMap(pos);
                },
                () => {
                    setLocationInfo('Unable to retrieve your location');
                }
            );
        } else {
            setLocationInfo('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        fetchAllBikeLocations();
        const interval = setInterval(fetchAllBikeLocations, 30000); // Update every 30 seconds
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (map && bikeLocations.length > 0) {
            bikeLocations.forEach(bike => addBikeMarker(bike));
        }
    }, [bikeLocations, map]);

    const fetchAllBikeLocations = async () => {
        try {
            const promises = deviceKeys.map(deviceKey =>
                fetch(`https://bot.millatumidi.uz/bikes?deviceKey=${deviceKey.trim()}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'bypass-tunnel-reminder': 'true',
                    },
                })
                    .then(response => response.json())
                    .then(data => ({
                        deviceKey,
                        location: { lat: data.Lat, lng: data.Lng }
                    }))
                    .catch(error => {
                        console.error(`Failed to fetch bike location for device ${deviceKey}:`, error);
                        return null;
                    })
            );

            const results = await Promise.all(promises);
            const validLocations = results.filter(result => result !== null);
            setBikeLocations(validLocations);
        } catch (error) {
            console.error('Failed to fetch bike locations:', error);
        }
    };

    const requestCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: { ideal: 'environment' }
                }
            });
            setMediaStream(stream);
            return true;
        } catch (err) {
            console.error('Camera permission error:', err);
            if (err.name === 'NotAllowedError') {
                setCameraError('Please enable camera access in your browser settings');
            } else if (err.name === 'NotFoundError') {
                setCameraError('No camera found on your device');
            } else {
                setCameraError(`Camera error: ${err.message}`);
            }
            return false;
        }
    };

    const handleScannerOpen = async () => {
        setCameraError(null);

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setModalMessage('Camera access is not supported by this browser');
            setIsOpen(true);
            return;
        }

        const hasPermission = await requestCameraPermission();
        if (hasPermission) {
            setScanning(true);
        } else {
            setModalMessage(cameraError || 'Unable to access camera');
            setIsOpen(true);
        }
    };

    const handleScan = (data) => {
        if (data) {
            const userId = Cookies.get('userId');
            setScanResult(data.text);
            setScanning(false);
            stopCamera();

            fetch(`https://bot.millatumidi.uz/bikes/rent?deviceKey=${encodeURIComponent(data.text)}&userId=${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'bypass-tunnel-reminder': 'true',
                }
            })
                .then(response => {
                    setModalMessage(`Bike rented successfully! Device Key: ${data.text}`);
                    setIsOpen(true);
                    fetchAllBikeLocations(); // Refresh bike locations after rental
                })
                .catch(error => {
                    setModalMessage('Failed to rent the bike. Please try again.');
                    setIsOpen(true);
                });
        }
    };

    const stopCamera = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
        setScanning(false);
        setCameraError(null);
    };

    const initializeMap = (pos) => {
        const map = new window.google.maps.Map(document.getElementById("map"), {
            center: pos,
            zoom: 15,
            disableDefaultUI: true,
        });
        setMap(map);

        // Add user location marker
        new window.google.maps.Marker({
            position: pos,
            map: map,
            title: 'Your Location',
            icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 12,
                fillColor: "#FF0000",
                fillOpacity: 1,
                strokeWeight: 2
            }
        });

        // Handle window resize
        window.addEventListener('resize', () => {
            map.setCenter(pos);
        });
    };

    const addBikeMarker = (bike) => {
        if (!map || !bike.location) return;

        new window.google.maps.Marker({
            position: bike.location,
            map: map,
            title: `Bike ${bike.deviceKey}`,
            icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 12,
                fillColor: "#0000FF",
                fillOpacity: 1,
                strokeWeight: 2
            }
        });
    };

    return (
        <div className="relative h-screen w-full">
            <div id="map" className="h-full w-full" />

            <div className="absolute top-6 left-0 right-0 mx-4">
                <div className="flex items-center bg-white p-3 rounded-lg shadow-lg">
                    <div className="p-2 rounded-full">
                        <img src={locationImage} alt="Location" className="h-8 w-8"/>
                    </div>
                    <div className="ml-4 text-gray-700">
                        <p className="text-sm md:text-base">Current Location</p>
                        <p className="text-xs md:text-sm">{locationInfo}</p>
                    </div>
                </div>
            </div>

            <div className="absolute bottom-10 right-4 flex flex-col space-y-4 mb-12">
                <button
                    className="p-4 rounded-full shadow-lg bg-white hover:bg-gray-100 transition-colors"
                    onClick={handleScannerOpen}
                >
                    <img src={scanImage} alt="Scan" className="h-10 w-10" />
                </button>
            </div>

            {scanning && (
                <div className="absolute inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
                        <div className="p-4">
                            <h3 className="text-lg font-semibold text-center mb-4">Scan QR Code</h3>
                            {cameraError ? (
                                <div className="text-red-500 text-center mb-4 p-4">
                                    <p>{cameraError}</p>
                                    <button
                                        onClick={handleScannerOpen}
                                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Try Again
                                    </button>
                                </div>
                            ) : (
                                <QrScanner
                                    delay={300}
                                    style={{ height: '300px', width: '100%' }}
                                    onError={error => {
                                        console.error(error);
                                        setCameraError(error.message);
                                    }}
                                    onScan={handleScan}
                                    constraints={{
                                        video: {
                                            facingMode: { ideal: "environment" }
                                        }
                                    }}
                                />
                            )}
                            <div className="flex justify-center mt-4">
                                <button
                                    onClick={stopCamera}
                                    className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-lg"
                                >
                                    Close Scanner
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal isOpen={isOpen} message={modalMessage} onClose={() => setIsOpen(false)} />
            <BottomNavBar />
        </div>
    );
};

export default MapComponent;